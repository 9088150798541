import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['link', 'select', 'mspCheckbox'];

  connect() {
    this.mspCheckboxChanged();

    if (this.selectTarget) {
      this.onChange({ target: this.selectTarget });
    }

    document.addEventListener("htmx:afterSwap", (event) => {
      if (this.selectTarget) {
        this.onChange({ target: this.selectTarget });
      }
    });
  }

  onChange(event) {
    const subscription = document.getElementById('subscription');

    if (event.target.value !== '') {  
      const inputs = subscription.querySelectorAll('input');
      inputs.forEach(input => {
        input.disabled = true;
      });
      subscription.classList.add("hidden"); 
    } else {
      const inputs = subscription.querySelectorAll('input');  
      inputs.forEach(input => {
        input.disabled = false;
      });
      subscription.classList.remove("hidden");
    }
  }

  mspCheckboxChanged() {
    const isMspSelected = this.mspCheckboxTarget.checked;
    const parent = document.getElementById('parentCompanySection');
    const subscription = document.getElementById('subscription');

    if (isMspSelected) {
        parent.classList.add("hidden");
        const inputs = subscription.querySelectorAll('input');  
        
        inputs.forEach(input => {
          input.disabled = false;
        }); 
        subscription.classList.remove("hidden");
    } else {
        parent.classList.remove("hidden");
    }
  }

  handleClick(event) {
    event.preventDefault(); 

    const link = event.currentTarget;
    const paramToRemove = link.id;

    
    const currentUrl = new URL(window.location.href);

    currentUrl.searchParams.delete(paramToRemove);
    window.location.href = currentUrl.toString();  
  }

}