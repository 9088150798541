import Choices from "choices.js";
import {Controller} from "@hotwired/stimulus";
import templates from "../choices_template_callbacks";

const filterMaps = new Map();
filterMaps.set("email", /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
filterMaps.set("ip", /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/);

export default class ChoiceController extends Controller {
    static values = {
        maxItems: {type: Number, default: -1},
        placeholder: String,
        removeButton: Boolean,
        searchEnabled: {type: Boolean, default: true},
        sort: Boolean,
        url: String,
        selectText: {type: String, default: "Press to Select"},
        template: String,
        choices: {type: Array, default: []},
    };

    /**
     * returns the choice instances.
     * @return {Object} The choice instance.
     */
    get choices() {
        return this.data.get("instance");
    }

    /**
     * sets the choice instances.
     * @param {Object} instance - The choice instance.
     */
    set choices(instance) {
        this.data.set("instance", instance);
    }

    connect() {
        if (this.element[this.identifier] !== undefined) {
            return;
        }

        this.element[this.identifier] = this;
    }

    initialize() {
        if (this.element.classList.contains("choices")) {
            return;
        }

        // Removing data-controller
        delete this.element.dataset.controller;

        const input     = this.element;
        const trueValue = "true";

        const config = {
            addItemFilter: function (value) {
                if (!value) {
                    return true;
                }

                if (!input.dataset.patternFilter) {
                    return true;
                }

                const regex      = filterMaps.get(input.dataset.patternFilter);
                const expression = new RegExp(regex.source, "i");
                return expression.test(value);
            },
            customAddItemText: input.dataset.patternText || "Only values matching specific conditions can be added",
            duplicateItemsAllowed: input.dataset.allowDuplicated === trueValue,
            maxItemCount: this.maxItemsValue,
            maxItemText: (maxItemCount) => {
                return `Max ${maxItemCount} ${input.dataset.name} can be selected.`;
            },
            noChoicesText: `No more ${input.dataset.name} to filter by`,
            placeholder: this.placeholderValue !== "",
            placeholderValue: this.placeholderValue || null,
            removeItemButton: this.removeButtonValue,
            resetScrollPosition: false,
            searchChoices: !this.hasUrlValue,
            shouldSort: this.sortValue,
            shouldSortItems: this.sortValue,
            searchEnabled: this.searchEnabledValue,
            itemSelectText: this.selectTextValue,
            sortFn: (a, b) => (parseInt(b.value)) < (parseInt(a.value)) ? 1 : -1,
        };

        if (this.templateValue !== "") {
            config.callbackOnCreateTemplates = _retrieveChoiceCallbackTemplate(this.templateValue);
        }

        this.instance = new Choices(this.element, config).setChoiceByValue(this.element.value);
        if (this.choicesValue.length > 0) {
            this.instance.setChoiceByValue(this.choicesValue);
            delete this.element.dataset.choicesChoicesValue;
        }

        // Loading data and Search Listener if choices is Async
        this.load().then(resp => {
            if (!resp) {
                return;
            }

            if (this.element.multiple) {
                // Skipping choices already added.
                resp = _skipSelected(resp, this.instance.getValue());
            }

            // Setting choices.
            this.instance.setChoices(resp, "value", "label", true);
        });
    }

    destroy() {
        this.instance.destroy();
    }

    /**
     * loads the initial data that will be presented in the choices' selector.
     * @return {Promise} The data list.
     */
    async load() {
        // Not loading initial data if url has not been specified.
        if (!this.hasUrlValue) {
            return;
        }

        const hasBaseData = this.instance.config.choices.length > 0;
        this.element.addEventListener("search", this.search, false);

        // Not loading initial data if already contains base data.
        if (hasBaseData) {
            return;
        }

        const resp = await fetch(this.urlValue);
        return resp.json();
    }

    /**
     * searches the data on the database based on the keywords typed in the search box.
     */
    search(event) {
        const controller = event.target["choices"];
        const selected   = controller.instance.getValue();

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const url = new URL(controller.urlValue, window.location.origin);
            url.searchParams.set("keyword", event.detail.value);

            // Retrieving data from URL.
            fetch(url.toString()).then(resp => {
                return resp.json();
            }).then(data => {
                if (!data) {
                    controller.instance.setChoices([], "value", "label", true);
                    return;
                }

                if (controller.element.multiple) {
                    // Skipping choices already added.
                    data = _skipSelected(data, selected);
                }

                // Replacing dropdown options with the new ones.
                controller.instance.setChoices(data, "value", "label", true);
            });
        }, 250);
    }
}

/**
 * skips choices already selected from the item list.
 * @param {Array} all - The list of items.
 * @param {Array} selected - The list of selected items.
 * @return {Array} The list of items filtered.
 */
function _skipSelected(all, selected) {
    return all.reduce((prev, current) => {
        let isDuplicate = false;

        for (const element of selected) {
            if (current.value === element.value) {
                isDuplicate = true;
                break;
            }
        }

        if (!isDuplicate) {
            prev.push(current);
        }

        return prev;
    }, []);
}

/**
 * returns a custom HTML template for showing the choices.
 * @param {Array} tmpl - The name of the template to use.
 * @return {Function} The custom callback choice template.
 */
function _retrieveChoiceCallbackTemplate(tmpl) {
    switch (tmpl) {
        case "targetTemplates":
            return templates.targetTemplates;
        case "targetTraining":
            return templates.targetTraining;
    }
}
